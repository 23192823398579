import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-538e48ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "nav nav-pills mb-3",
  id: "pills-tab",
  role: "tablist"
}
const _hoisted_2 = { class: "table" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EnterBaihai = _resolveComponent("EnterBaihai")!
  const _component_Contact = _resolveComponent("Contact")!
  const _component_Journalism = _resolveComponent("Journalism")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(_ctx.scrollTop>575? 'introduceTable conditions-met' : 'introduceTable')
    }, [
      _createElementVNode("ul", _hoisted_1, [
        _createElementVNode("li", {
          class: "nav-item",
          role: "presentation",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.changeActive(1)))
        }, [
          _createElementVNode("button", {
            class: _normalizeClass(_ctx.active===1? 'btn-link active' : 'btn-link'),
            isid: "1",
            id: "pills-home-tab",
            "data-bs-toggle": "pill",
            "data-bs-target": "#pills-home",
            type: "button",
            role: "tab",
            "aria-controls": "pills-home",
            "aria-selected": "true"
          }, "走进白海", 2)
        ]),
        _createElementVNode("li", {
          class: "nav-item",
          role: "presentation",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.changeActive(2)))
        }, [
          _createElementVNode("button", {
            class: _normalizeClass(_ctx.active===2? 'btn-link active' : 'btn-link'),
            isid: "2",
            id: "pills-profile-tab",
            "data-bs-toggle": "pill",
            "data-bs-target": "#pills-profile",
            type: "button",
            role: "tab",
            "aria-controls": "pills-profile",
            "aria-selected": "false"
          }, "联系我们", 2)
        ])
      ])
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.active===1)
        ? (_openBlock(), _createBlock(_component_EnterBaihai, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.active===2)
        ? (_openBlock(), _createBlock(_component_Contact, { key: 1 }))
        : _createCommentVNode("", true),
      (_ctx.active===3)
        ? (_openBlock(), _createBlock(_component_Journalism, { key: 2 }))
        : _createCommentVNode("", true)
    ])
  ], 64))
}