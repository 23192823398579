
import { defineComponent, ref } from 'vue'
import EnterBaihai from './EnterBaihai.vue'
import Contact from './Contact.vue'
import Journalism from './Journalism.vue'
import useScrollDistance from '../hooks/useScrollDistance'
export default defineComponent({
  name: 'IntroduceTable',
  components: {
    EnterBaihai,
    Contact,
    Journalism
  },
  setup () {
    const active = ref<number>(1)
    const scrollTop = useScrollDistance()
    const changeActive = (num: number) => {
      active.value = num
    }
    return {
      changeActive,
      active,
      scrollTop
    }
  }
})
