
import { defineComponent } from 'vue'
import Map from '../components/Map.vue'
export default defineComponent({
  name: 'Contact',
  components: {
    Map
  },
  setup () {
    return {}
  }
})
