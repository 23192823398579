
import { defineComponent, onMounted } from 'vue'
import IntroduceTable from '@/components/IntroduceTable.vue'
;
export default defineComponent({
  name: 'introduce',
  components: {
    IntroduceTable
  },
  setup() {
    onMounted(() => {
      _hmt.push(['_trackPageview', '/introduce'])
    })
  }
})
