<template>
  <div id="allmap"></div>
</template>
<script>
import img from '../assets/img/introduce/位置.png'
export default {
  name: 'Map',
  mounted () {
    this.createMap()
  },
  methods: {
    createMap () {
      const map = new window.BMapGL.Map('allmap')// 创建Map实例
      var point = new window.BMapGL.Point(116.35053, 40.066808)
      map.centerAndZoom(point, 18)// 初始化地图,设置中心点坐标和地图级别
      map.enableScrollWheelZoom(true)// 开启鼠标滚轮缩放
      map.setHeading(64.5)
      map.setTilt(73)
      var myIcon = new window.BMapGL.Icon(img, new window.BMapGL.Size(48, 42))
      var marker1 = new window.BMapGL.Marker(point, { icon: myIcon })
      map.addOverlay(marker1)

      var opts = {
        position: new window.BMapGL.Point(116.35053, 40.066808), // 指定文本标注所在的地理位置
        offset: new window.BMapGL.Size(-40, -70) // 设置文本偏移量
      }
      // 创建文本标注对象
      var label = new window.BMapGL.Label('白海科技', opts)
      // 自定义文本标注样式
      label.setStyle({
        color: 'black',
        borderRadius: '5px',
        borderColor: '#ccc',
        padding: '10px',
        fontSize: '16px',
        height: '40px',
        lineHeight: '20px',
        fontFamily: '微软雅黑'
      })
      map.addOverlay(label)
      marker1.setAnimation('白海科技')
    }
  }
}
</script>

<style scoped>
#allmap {
  height: 320px;
}
</style>
